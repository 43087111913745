<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import customTable from "@/components/paginate-table.vue";
import axios from "axios";
import mobileView from "../../../components/mobile-view.vue";
import { detectScreen } from "@/_helpers";

/**
 * Starter page
 */
export default {
  components: { Layout, PageHeader, customTable, mobileView },
  page: {
    title: "Data Pelanggan",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Pelanggan",
          active: true,
        },
      ],
      screenWidth: detectScreen(),
      dataKey: "customers",
      categoryName: [],
      apiUrl: process.env.VUE_APP_API_BASE_URL + "customers",
      apiCategory: process.env.VUE_APP_API_BASE_URL + "customer-categories",
      columns: [
        {
          prop: "name",
          label: "Name",
          width: "150",
          sortable: true,
        },
        {
          prop: "customer_category.name",
          label: "Kategori",
          width: "100",
          center: true,
        },
        {
          prop: "email",
          label: "Email",
          width: "150",
          textdefault: true,
        },
        {
          prop: "phone_number",
          label: "No.Hp",
          width: "150"
        },
        {
          prop: "province.name",
          label: "Provinsi",
          width: "150",
        },
        {
          prop: "regency.name",
          label: "Kab / Kota",
          width: "150",
        },
        {
          prop: "district.name",
          label: "Kecamatan",
          width: "150"
        },
        {
          prop: "village.name",
          label: "Desa / Kelurahan",
          width: "150"
        }
      ],
    };
  },
  methods: {
    async getCategoryId() {
      try {
        this.isLoading = true;
        await axios.get(this.apiCategory).then((response) => {
          const uniqueRoles = [
            ...new Set(
              response.data.data.map((data) => {
                return data;
              })
            ),
          ];

          this.categoryName = uniqueRoles.map((role) => ({
            name: role.name,
            value: role.name,
            id: role.id,
          }));
        });
      } catch (error) {
        console.error(error);
      }
    },
    onScreenResize() {
      this.screenWidth = detectScreen();
    },
  },
  mounted() {
    this.getCategoryId();
  },
  middleware: "authentication",
};
</script>

<template>
  <Layout v-if="screenWidth >= 860">
    <PageHeader :title="$route.name" :items="items" />
    <b-card>
      <b-card-body>
        <custom-table
          ref="dataTable"
          :with-form-add="true"
          :form-url="'/pelanggan/tambah'"
          :with-filter-name="true"
          :with-delete="true"
          :with-print="false"
          :with-edit="true"
          :data-url="apiUrl"
          :data-key="dataKey"
          :data-columns="columns"
          :with-fixed="true"
          params-category="customer_category_id"
          :with-filter-category-order="true"
          :select-options="categoryName"
          filter-with="name"
          title-filter="Filter Berdasarkan Nama, Email, & No HP"
        ></custom-table>
      </b-card-body>
    </b-card>
  </Layout>
  <mobileView
    v-else
    ref="dataTable"
    title-filter="Filter Berdasarkan Nama, Email, & No HP"
    :with-filter-category="true"
    :with-filter-text="true"
    :form-url="'/pelanggan/tambah'"
    params-category="customer_category_id"
    :data-url="apiUrl"
    :select-options="categoryName"
    :card-customer="true"
    :card-default="false"
  ></mobileView>
</template>
